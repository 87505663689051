/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY, Observable, of } from "rxjs";
import { catchError, map, mergeMap, toArray } from "rxjs/operators";
import { z } from "zod";

import { Storage } from "~libs/storage";
import { BrowsableItem } from "~models/browsableItem";
import { Category } from "~models/category";
import { Channel } from "~models/channel";
import { Collection } from "~models/collection";
import { Event } from "~models/event";
import { Extrait } from "~models/extrait";
import { Integrale } from "~models/integrale";
import { Metadata } from "~models/metadata";
import { Program } from "~models/program";
import { Unit } from "~models/unit";
import { ILoggedInUser, PUBLIC_ID_STORAGE_KEY, User, USER_ID_STORAGE_KEY } from "~models/user";
import { ErrorPage } from "~pages/popup/popupPage";
import { platform, PlatformType } from "~ui-lib";

import { Flux } from "../models/flux";
import { ItemCollection } from "../models/itemCollection";
import { Partner } from "../models/partner";
import { PlayableItem } from "../models/playableItem";
import { Region } from "../models/region";
import { Tag } from "../models/tag";
import { UserInfo } from "../models/userInfo";
import { AppConsents } from "../tools/cmp/didomi";
import { RecoConsentBannerHandler } from "../tools/cmp/recoConsentBannerHandler";
import { getVisitorMode } from "../tools/cmp/visitorMode";
import { PlayHistoryHelper } from "../tools/playerHistoryHelper";
import { ContentRatingsHelper, LikeOrDislike, TaxonomyRatingsHelper } from "../tools/ratingsHelper";
import { Config, IConfigConnexionUrls } from "./../config";
import { navigationStack } from "./../main";
import { BackendErrorException, UnknownException } from "./exceptions";
import {
  parseAds,
  parseBookmarks,
  ParsedAdBreaks,
  parseDirects,
  parseHome,
  parseItems,
  parseJSON,
  parseMenu,
  parseSearch,
  parseToken,
  parseTxtFavorite,
} from "./parser";
import { parseRatings } from "./parseRatings";
import { Dictionary, Request, RequestMethod } from "./request";

const _apiMobilePlatformParam = {
  platform: {
    [PlatformType.hisense]: "smart_tv" as const,
    [PlatformType.other]: "smart_tv" as const,
    [PlatformType.tizen]: "smart_tv" as const,
    [PlatformType.webos]: "smart_tv" as const,
    [PlatformType.orange]: "orange-ng" as const,
    [PlatformType.philips]: "smart_tv" as const,
  }[platform.type],
} as const;

export const apiMobilePlatformParam = _apiMobilePlatformParam;

/**
 * The Plugin class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 * it is the service used for request and all private datas needed in the project
 */
export class Plugin {
  private static instance: Plugin;

  request: Request;
  readonly apiMobileURL: string;
  playerURL: string;
  adsURL: string;
  seeksURL: string;
  connexionURLs: IConfigConnexionUrls;
  device: string;
  favoriteURL: string;
  didomi: typeof Config["didomi"]["prod" | "preprod"];
  hmacKey: string;
  proxyGin: string;
  user: User;
  isLaunched: boolean;
  private readonly ratingsURL: string;

  /**
   * The Plugin's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    this.request = new Request();
    this.isLaunched = false;
    if (__BACKEND_TARGET__ === "prod" || __BACKEND_TARGET__ === "proxy") {
      this.apiMobileURL = Config.server.prod;
      this.playerURL = Config.player.prod;
      this.adsURL = Config.ads.prod;
      this.seeksURL = Config.seeks.prod;
      this.favoriteURL = Config.favorites.prod;
      this.ratingsURL = Config.ratings.prod;
      this.didomi = Config.didomi.prod;
      this.proxyGin = Config.proxyGin.prod;
      this.connexionURLs = Config.connexion.prod;
    } else {
      this.apiMobileURL = Config.server.preprod;
      this.playerURL = Config.player.preprod;
      this.adsURL = Config.ads.preprod;
      this.seeksURL = Config.seeks.preprod;
      this.favoriteURL = Config.favorites.preprod;
      this.ratingsURL = Config.ratings.preprod;
      this.didomi = Config.didomi.preprod;
      this.proxyGin = Config.proxyGin.preprod;
      this.connexionURLs = Config.connexion.preprod;
    }

    this.hmacKey =
      __BACKEND_TARGET__ === "prod" || __BACKEND_TARGET__ === "proxy"
        ? "aV3JF58z8zPeCPtdICt7RNBN7DgazdcX"
        : "7pTFEFibDBuT6WqW8cm9r4nkO4Cj9RY6";

    if (platform.type == PlatformType.tizen) {
      this.device = "samsung";
    } else {
      this.device = "lg";
    }

    this.user = new User();
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Plugin class while keeping
   * just one instance of each subclass around.
   */

  public static getInstance(): Plugin {
    if (!Plugin.instance) {
      Plugin.instance = new Plugin();
    }

    return Plugin.instance;
  }

  /**
   * Finally, any singleton should define some business logic, which can be
   * executed on its instance.
   */

  public fetchApiMobile(
    action: string,
    method?: RequestMethod,
    params?: Dictionary<unknown>,
    headers?: Dictionary<string>
  ): Observable<string> {
    return this.request.fetchJson(
      this.apiMobileURL + action,
      method,
      { ...params, ..._apiMobilePlatformParam },
      headers
    );
  }

  public fetchURL(
    url: string,
    method?: RequestMethod,
    params?: Dictionary<unknown>,
    headers?: Dictionary<string>,
    withCredentials?: false,
    specificTimeout?: number
  ): Observable<string> {
    return this.request.fetchJson(url, method, params, headers, withCredentials, specificTimeout);
  }

  public fetchEstat(
    url: string,
    method?: RequestMethod,
    params?: Dictionary<string>,
    headers?: Dictionary<string>
  ): Observable<string> {
    return this.request.fetchTxtJson(url, method, params, headers);
  }

  public fetchSeeks(
    url: string,
    method?: RequestMethod,
    params?: Dictionary<string>,
    headers?: Dictionary<string>
  ): Observable<string> {
    return this.request.fetchBody(this.seeksURL + url, method, params, headers);
  }

  public fetchFavorites(
    action: string,
    method?: RequestMethod,
    params?: Dictionary<string>,
    headers?: Dictionary<string>
  ): Observable<string> {
    return this.request.fetchTxtJson(this.favoriteURL + action, method, params, headers);
  }

  public fetchStubs(name: string): Observable<string> {
    return this.request.readJson(name);
  }

  public fetchXML(url: string): Observable<string> {
    return this.request.fetchXML(url);
  }

  /**
   * Catch error from request BackendErrorException | UnknownException
   * Display/push popup error message on fullscreen
   *
   * Display a popup page
   * On release, pop up page will remove the top page
   * /!\ if navigation stack is empty, HomePage will be pushed (Error Page behiavor)
   */
  public fetchError(error: BackendErrorException | UnknownException): void {
    const description =
      error instanceof BackendErrorException ? (error.backendCode ? "(Erreur " + error.backendCode + ")" : "") : "";
    const topPage = navigationStack.topPage;
    navigationStack.pushPage(
      new ErrorPage(
        {
          title: error.message,
          description: description,
        },
        topPage
      )
    );
  }

  public createSignatureHMAC(path: any, accessToken?: any, refreshToken?: any, requestBody?: any) {
    const crypto = require("crypto");
    const base64Url = require("base64-url");
    const contentOptions: any = {};
    // {
    //   accessToken: null,
    //   path: null,
    //   proxyUserId: platform.deviceId || null,
    //   refreshToken: null,
    //   requestBody: null,
    // };
    if (accessToken && accessToken.trim().includes("Bearer ")) {
      contentOptions.accessToken = accessToken.substring(7);
    }
    contentOptions.path = path;
    contentOptions.proxyUserId = platform.deviceId;
    if (refreshToken) {
      contentOptions.refreshToken = refreshToken;
    }
    if (requestBody && Object.keys(requestBody).length > 0) {
      contentOptions.requestBody = requestBody;
    }
    // on serialise le tout
    const content = JSON.stringify(contentOptions);
    // on crée la signature à l'aide du module crypto
    // on encode et escape en base64 la signature
    return base64Url.escape(crypto.createHmac("sha256", this.hmacKey).update(content).digest("base64"));
  }

  public generateTransactionXAuthToken(userId: string) {
    const crypto = require("crypto");
    try {
      const timestamp = Date.now() / 1000 + 3600 * 24;
      const message = "Ce user: " + userId + " a les droits jusqu'à " + timestamp + " ";
      const hash = crypto.createHash("sha256").update(message).digest("base64");
      return Buffer.from(userId).toString("base64") + "|" + timestamp + "|" + hash;
    } catch (error) {
      Log.api.error("Error generating X-Auth-Token:", error);
      throw error;
    }
  }

  /**
   * Get dynamic menu items for navigation
   */
  public fetchMenu(): Observable<any> {
    // TODO: replace with fetch url when api will be created on FTV back-end
    return this.fetchStubs("stubs_menu").pipe(
      mergeMap(json => {
        return parseMenu(json);
      }),
      toArray(),
      map(items => {
        return items.sort((c1, c2) => {
          return c1.order - c2.order;
        });
      })
    );
  }

  /**
   * Post get code to display
   */
  public fetchCode(): Observable<any> {
    return this.fetchURL(this.proxyGin + "/v3/francetv/user/login/code", "POST", undefined, {
      "X-HMAC-Signature": this.createSignatureHMAC("/v3/francetv/user/login/code"),
      "X-User": platform.deviceId,
      "X-Source": "francetv-smarttv",
      "Content-Type": "application/json",
    }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      toArray()
    );
  }

  /**
   * Get user info
   */
  public fetchUser(userId: any, tokenJson: any): Observable<any> {
    return this.fetchURL(this.proxyGin + "/v3/francetv/user/get-info/" + userId, "GET", undefined, {
      "X-HMAC-Signature": this.createSignatureHMAC(
        "/v3/francetv/user/get-info/" + userId,
        "Bearer " + tokenJson?.access_token || Storage.getItem("access_token") || "",
        tokenJson?.refresh_token || Storage.getItem("refresh_token") || ""
      ),
      "X-User": platform.deviceId,
      "X-Source": "francetv-smarttv",
      "X-Refresh-Token": tokenJson?.refresh_token || Storage.getItem("refresh_token") || "",
      "Content-Type": "application/json",
      Authorization: "Bearer " + tokenJson?.access_token || Storage.getItem("access_token") || "",
    }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        if (e.message === "Access token has expired" && Storage.getItem(PUBLIC_ID_STORAGE_KEY)) {
          return of({
            email: "",
            publicId: Storage.getItem(PUBLIC_ID_STORAGE_KEY),
          });
        } else {
          this.fetchError(e);
          return EMPTY;
        }
      }),
      mergeMap((userJson: unknown) => {
        this.login(userId, UserInfo.parse(userJson));
        return of(tokenJson);
      })
    );
  }

  /**
   * Post check displayed code if validate online
   * receive access Token with UserId
   */
  public fetchCheckCode(codeUser: string): Observable<any> {
    return this.fetchURL(
      this.proxyGin + "/v3/francetv/user/login/status",
      "POST",
      {
        code: codeUser,
      },
      {
        "X-HMAC-Signature": this.createSignatureHMAC("/v3/francetv/user/login/status", undefined, undefined, {
          code: codeUser,
        }),
        "X-User": platform.deviceId,
        "X-Source": "francetv-smarttv",
        "Content-Type": "application/json",
      }
    ).pipe(
      mergeMap(json => {
        return this.fetchLogin(json, false);
      }),
      toArray()
    );
  }

  /**
   * Get logout user
   */
  public fetchLogout(): Observable<any> {
    const logoutReturn = this.fetchURL(this.proxyGin + "/v3/francetv/user/logout/" + this.user.id, "GET", undefined, {
      "X-HMAC-Signature": this.createSignatureHMAC(
        "/v3/francetv/user/logout/" + this.user.id,
        "Bearer " + Storage.getItem("access_token"),
        Storage.getItem("refresh_token")
      ),
      "X-User": platform.deviceId,
      "X-Source": "francetv-smarttv",
      "X-Refresh-Token": Storage.getItem("refresh_token") || "",
      "Content-Type": "application/json",
      Authorization: "Bearer " + Storage.getItem("access_token"),
    }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        return EMPTY;
      }),
      toArray()
    );
    this.deconnect();
    return logoutReturn;
  }

  /**
   * Get new access Token
   */
  public fetchReconnect(): Observable<any> {
    const userId = this.user.id || Storage.getItem(USER_ID_STORAGE_KEY) || "";
    return this.fetchURL(
      this.proxyGin + "/v3/francetv/user/reconnect/" + userId,
      "GET",
      undefined,
      {
        "X-HMAC-Signature": this.createSignatureHMAC(
          "/v3/francetv/user/reconnect/" + userId,
          undefined,
          Storage.getItem("refresh_token")
        ),
        "X-User": platform.deviceId,
        "X-Source": "francetv-smarttv",
        "X-Refresh-Token": Storage.getItem("refresh_token") || "",
        "Content-Type": "application/json",
      },
      undefined,
      5000
    ).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        if (
          Storage.getItem("refresh_token") &&
          e instanceof BackendErrorException &&
          (e.status === 500 ||
            e.status === 503 ||
            (e.backendCode === "ECONNABORTED" && e.message.startsWith("Timeout")))
        ) {
          return of({
            access_token: Storage.getItem("access_token") || "",
            refresh_token: Storage.getItem("refresh_token") || "",
          });
        } else {
          //to force disconnect
          throw "failure reconnect";

          this.fetchError(e);
          return EMPTY;
        }
      }),
      mergeMap(json => {
        return this.fetchLogin(json, true);
      }),
      toArray()
    );
  }

  public fetchLogin(tokenJson: any, reconnect: boolean): Observable<any> {
    return parseToken(tokenJson)
      .pipe(
        catchError((e: BackendErrorException | UnknownException) => {
          this.fetchError(e);
          return EMPTY;
        }),
        mergeMap((json: any) => {
          Storage.setItem("access_token", json.access_token);
          if (reconnect === false) {
            Storage.setItem("refresh_token", json.refresh_token || "");
          }

          // this.login(json.tokenDecoded.userId);
          return of(json.tokenDecoded.userId);
        })
      )
      .pipe(
        mergeMap(userId => {
          return this.fetchUser(userId, tokenJson);
        })
      );
  }

  /**
   * Get CGU
   */
  public fetchCGU(): Observable<any> {
    return this.fetchStubs("cgu").pipe(toArray());
  }
  /**
   * Get mentions légale
   */
  public fetchMentionLegale(): Observable<any> {
    return this.fetchStubs("mention_legal").pipe(toArray());
  }

  /**
   * Get all swimlanes in Home Tab
   */
  public fetchHome(): Observable<any> {
    return this.fetchApiMobile("/apps/page/_").pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseHome(json);
      }),
      toArray()
    );
  }

  /**
   */
  public fetchCurrentDirect(channelUrl: string): Observable<BrowsableItem> {
    return this.fetchApiMobile("/apps/directs/" + channelUrl).pipe(
      mergeMap(json => {
        const parseResult = z.object({ item: z.unknown() }).safeParse(json);
        if (parseResult.success) {
          return parseItems(parseResult.data.item);
        } else {
          throw "Parse Error fetchCurrentDirect";
        }
      })
    );
  }

  /**
   * Get all swimlanes in Directs Tab
   */
  public fetchDirects(): Observable<any> {
    return this.fetchApiMobile("/generic/directs").pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseDirects(json);
      }),
      toArray()
    );
  }

  /**
   * Get all Channels for swimlane channels example
   */
  public fetchChannels(): Observable<any> {
    return this.fetchApiMobile("/generic/channels", "GET", { "with-partners": true }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseItems(json);
      }),
      toArray()
    );
  }

  /**
   * Get all resume content for swimlane Mes lecture en cours
   */
  public fetchResumeSwimlane(user: ILoggedInUser): Observable<any> {
    return this.fetchApiMobile("/apps/transactions/" + user.id + "/seeks", "GET", undefined, {
      "x-auth-token": this.generateTransactionXAuthToken(user.id),
      "Content-Type": "application/json",
    }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        Log.api.error(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseItems(json);
      }),
      toArray()
    );
  }

  /**
   * Get all recommendations for swimlane recommendations in my videos and the homepage
   */
  public fetchRecommendations(user: ILoggedInUser): Observable<any> {
    return this.fetchApiMobile("/apps/recommendations/" + user.infos.publicId + "/homepage").pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        Log.api.error(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseItems(json);
      }),
      toArray()
    );
  }

  /**
   * Get all "because you watched " kinds of recommendations for swimlane recommendations in the homepage
   */
  public fetchBecauseYouWatched(user: ILoggedInUser): Observable<any> {
    return this.fetchApiMobile("/apps/recommendations/" + user.infos.publicId + "/similar-to-last-watched").pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        Log.api.error(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseItems(json);
      }),
      toArray()
    );
  }

  /**
   * Get all Categories for swimlane Categories example
   */
  public fetchCategories(): Observable<any> {
    return this.fetchApiMobile("/generic/categories").pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseItems(json);
      }),
      toArray()
    );
  }

  /**
   * Get all ChildCategories for swimlane ChildCategories example
   */
  public fetchChildCategories(): Observable<ItemCollection[]> {
    return this.fetchApiMobile("/generic/categories/enfants").pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseHome(json);
      }),
      toArray()
    );
  }

  /**
   * User actions
   */
  public login(userId: string, userInfo: UserInfo) {
    this.user.connect(userId, userInfo);
    this.fetchBookmarks(userId).subscribe(
      value => {
        PlayHistoryHelper.setList(parseBookmarks(value));
      },
      error => {
        Log.api.error("[FETCHBOOKMARKS] Error !", error);
      }
    );
    this.fetchRatings(userId)
      .then((json: unknown) => {
        try {
          const ratingsParsed = parseRatings(json);

          for (const contentRating of ratingsParsed.contents) {
            ContentRatingsHelper.set(contentRating.content.toString(), contentRating.rating);
          }

          for (const taxonomyRating of ratingsParsed.taxonomies) {
            TaxonomyRatingsHelper.set(taxonomyRating.content.toString(), taxonomyRating.rating);
          }
        } catch (e) {
          Log.app.error("fetchRatings parsing error", e);
        }
      })
      .catch((e: unknown) => {
        Log.api.error("[fetchRatings] Error !", e);
      });
  }

  public deconnect() {
    PlayHistoryHelper.clearlist();
    ContentRatingsHelper.clear();
    TaxonomyRatingsHelper.clear();
    RecoConsentBannerHandler.clearLastIgnoreDate();
    this.user.deconnect();
  }

  /**
   * Get if is a favorite content
   */
  public getIsFavorite(user: ILoggedInUser, contentId: string, category: "program" | "video"): Observable<boolean> {
    return this.fetchFavorites("/bookmarks/" + user.id + "/" + category + "/" + contentId, "GET", undefined, {
      "x-auth-token": this.generateTransactionXAuthToken(user.id),
      "Content-Type": "application/json",
    }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap((json: any) => {
        return parseTxtFavorite(json);
      })
    );
  }
  /**
   * Del if is a favorite content
   */
  public delFavorite(user: ILoggedInUser, contentId: string, category: "program" | "video"): Observable<string> {
    return this.fetchFavorites("/bookmarks/" + user.id + "/" + category + "/" + contentId, "DELETE", undefined, {
      "x-auth-token": this.generateTransactionXAuthToken(user.id),
      "Content-Type": "application/json",
    }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      })
    );
  }

  /**
   * Put add content to favorite
   */
  public addFavorite(user: ILoggedInUser, contentId: string, category: "program" | "video"): Observable<string> {
    return this.fetchFavorites(
      "/bookmarks/" + user.id + "/" + category,
      "PUT",
      {
        contentId: contentId,
        category: category,
      },
      {
        "x-auth-token": this.generateTransactionXAuthToken(user.id),
        "Content-Type": "application/json",
      }
    ).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      })
    );
  }

  /**
   * Get all ratings for a user
   */
  public fetchRatings(userId: string): Promise<unknown> {
    return this.fetchApiMobile("/apps/transactions/" + userId + "/ratings", "GET", undefined, {
      "x-auth-token": this.generateTransactionXAuthToken(userId),
      "Content-Type": "application/json",
    }).toPromise();
  }

  public sendRating(
    user: ILoggedInUser,
    params:
      | {
          type: "content";
          content: number;
          rating: LikeOrDislike;
          end_date: number;
        }
      | {
          type: "taxonomy";
          content: number;
          rating: LikeOrDislike;
        }
  ): Promise<unknown> {
    return this.fetchURL(this.ratingsURL + "/ratings/" + user.id, "PUT", params, {
      "x-auth-token": this.generateTransactionXAuthToken(user.id),
      "Content-Type": "application/json",
    }).toPromise();
  }

  /**
   * Get My Videos
   */
  public fetchMyVideos(user: ILoggedInUser): Observable<any> {
    return this.fetchApiMobile("/apps/mes-videos/" + user.id).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseHome(json);
      }),
      toArray()
    );
  }

  /**
   * Get dynamic url for player
   */
  public fetchPlayer(item: Unit | Extrait | Integrale, countryCode = "FR"): Observable<JSON> {
    // Preprod: https://player.webservices.ftv-preprod.fr/v1/videos/006194ea-117d-4bcf-94a9-153d999c59ae?device_type=smarttv&device=lg&domain=france.tv&country_code=FR
    // Prod: https://player.webservices.francetelevisions.fr/v1/videos/006194ea-117d-4bcf-94a9-153d999c59ae?device_type=smarttv&device=samsung&domain=france.tv&country_code=FR
    // Overwrite url to catch an error from player with: "https://player.webservices.francetelevisions.fr/v1/videos/0b8acaad-b738-4a61-a56e-7a8befc8d6c5?device_type=smarttv&device=lg&domain=france.tv&country_code=FR";

    // if the playableItem is a "live" and not "externe", it means that is a "live channel", the si_id have to be taken from extras.channel.si_id
    let si_id = undefined;
    if (
      item?.metadata?.extras?.is_live &&
      item?.media?.broadcast?.extras?.broadcast_channel &&
      item.media.broadcast.extras.broadcast_channel != "externe"
    ) {
      si_id = item?.extras?.channel?.extras?.si_id || item?.extras?.partner?.extras?.si_id;
    } else {
      si_id = item?.extras?.si_id;
    }
    if (si_id) {
      const url = this.playerURL + "/videos/" + si_id;
      const method = "GET";
      const body = {
        country_code: countryCode,
        domain: "france.tv",
        device: this.device,
        device_type: "smarttv",
        /**
         * We need to add `screen_w` and `screen_h` params on k7 API endpoint
         * to get back spritesheet tilesheets.
         *
         * Mobile (≥ 375 et < 600): 1280x720p
         * Tablette & PC petite taille (≥ 600 et < 1200): 1920x1080p
         * PC grande taille (≥ 1200 et < 1920): 3840x2160p
         * TV (≥ 1920): 5760x3240
         * Reference: https://francetv.atlassian.net/browse/SMARTTV-281?focusedCommentId=125958
         *
         * Using a dimension more than 1920x1080p seems to cause **major performance issue**.
         * To avoid having issue when navigating, we are faking dimension to 600px.
         */
        screen_w: "600",
        screen_h: "600",
      };
      return this.fetchURL(url, method, body).pipe(
        mergeMap((json: any) => {
          if (json.video && json.video.token && json.video.workflow == "token-akamai") {
            return this.fetchURL(json.video.token + "&url=" + json.video.url).pipe(
              mergeMap((tokenJson: any) => {
                json.video.url = tokenJson.url;
                return parseJSON(json);
              })
            );
          }
          return parseJSON(json);
        })
      );
    }
    return EMPTY;
  }

  /**
   * Get dynamic urls for player ads : preroll / midroll / postroll
   */
  public fetchAvailableAds(item: Unit | Extrait | Integrale): Observable<ParsedAdBreaks> {
    // Integrale, Extrait, Unit

    if (
      item.metadata &&
      item.extras &&
      item.metadata.extras &&
      !item.metadata.extras.is_live &&
      !item.extras.ads_blocked
    ) {
      let vastURL =
        this.adsURL +
        "/" +
        this.device +
        "?id_diffusion=" +
        item.extras.si_id +
        "&duration=" +
        item.metadata.duration +
        "&format=vmap";

      const cookieConsent = getVisitorMode().value === "enabled";
      const user = Plugin.getInstance().user;
      if (user.isActive() && cookieConsent === true) {
        /**
         * Public id needs to be sent only if user is logged in
         */
        const publicId = user.infos.publicId;

        /**
         * User public ID should only be sent if user
         * explicitely gave its consent.
         * ie. cookieConsent equals true
         */
        vastURL += `&terminalid=${publicId}`;

        /**
         * GDPR Consent string is obtained using Didomi
         * according IAB TCF 2.0
         * TODO: Implement didomi and add proper gdpr_content value
         */
        // vastURL += `&gdpr_consent=${gdrpConsent}`;
      }

      vastURL += `&_fw_h_user_agent=${encodeURI(navigator.userAgent)}`;
      vastURL += `&cookiesconsent=${cookieConsent}`;
      vastURL += "&gdpr=1";

      return this.fetchXML(vastURL).pipe(
        mergeMap((json: any) => {
          return parseAds(json);
        })
      );
    }

    return EMPTY;
  }

  /**
   * Get detailed home page for program, category or collection

   * program: http://api-mobile.yatta.francetv.fr/generic/program/france-2_la-faute-a-rousseau?platform=smart_tv
   * collections: http://api-mobile.yatta.francetv.fr/generic/collections/2298805?platform=smart_tv
   * category: https://api-mobile.yatta.francetv.fr/generic/page/films?platform=apps_tv
   * unitaire: video no live: http://api-mobile.yatta.francetv.fr/apps/contents/2261129/player?platform=smart_tv
   * event: https://api-mobile.yatta.ftv-preprod.fr/apps/events/sport_cyclisme_tour-de-france?platform=smart_tv
   * Unit no live will open player directly, same as Extrait: no detailed page before player
   * TODO: check if some items type Item exist but not handled in the switch case.
   * TODO: So far, I have only the infos for these 3 items
   */
  public fetchDetailed(item: BrowsableItem): Observable<any> {
    let path = "";

    if (item instanceof Program) {
      path = "/generic/program/" + item.extras.program_path;
    } else if (item instanceof Collection) {
      path = "/generic/collections/" + item.id;
    } else if (item instanceof Category) {
      path = "/generic/categories/" + item.extras.url_complete;
    } else if (item instanceof Unit && item.metadata && item.metadata.extras && !item.metadata.extras.is_live) {
      // path = "/apps/contents/" + item.id + "/player";
      path = "/generic/contents/" + item.id;
    } else if (item instanceof Unit || item instanceof Integrale || item instanceof Extrait || item instanceof Flux) {
      // path = "/apps/contents/" + item.id + "/player";
      path = "/generic/contents/" + item.id;
    } else if (item instanceof Event) {
      path = "/apps/events/" + item.extras.url_complete;
    } else if (item instanceof Tag) {
      path = "/apps/tags/" + item.extras.tag_path;
    } else if (item instanceof Channel) {
      path = "/apps/channels/" + item.extras.channel_url;
      if (item.extras.channel_url === "la1ere") {
        path = "/apps/regions/carrefour/outre-mer";
      }
    } else if (item instanceof Partner) {
      path = "/apps/partners/" + item.extras.partner_path;
    } else if (item instanceof Region) {
      path = `/apps/regions/${item.extras.region_path}/metropole`;
      if (item.extras.region_path.startsWith("la1ere")) {
        path = `/apps/regions/${item.extras.region_path}/outre-mer`;
      }
    } else {
      return EMPTY;
    }
    return this.fetchApiMobile(path).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseItems(json);
      }),
      toArray()
    );
  }

  public fetchItemById(id: string): Observable<any> {
    const item = new Integrale(id, "", "", "", [], new Metadata(null, 0), "", null, false, false);
    return this.fetchDetailed(item);
  }

  public fetchDefaultSearch(): Observable<any> {
    return this.fetchApiMobile("/apps/page/search/home", "GET", {
      "with-partners": true,
    }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseHome(json);
      }),
      toArray()
    );
  }
  public fetchSearch(searchText?: string): Observable<any> {
    if (searchText == undefined) return EMPTY;
    return this.fetchApiMobile("/generic/search", "GET", {
      term: encodeURIComponent(searchText),
      filters: "with-collections",
    }).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseSearch(json);
      }),
      toArray()
    );
  }

  public fetchNextEpisodes(item: PlayableItem): Observable<any> {
    if (item.metadata.extras.is_live)
      return this.fetchApiMobile("/generic/directs").pipe(
        mergeMap(json => {
          return parseDirects(json);
        }),
        toArray()
      );
    else
      return this.fetchApiMobile("/apps/contents/" + item.id + "/next-episodes").pipe(
        mergeMap(json => {
          return parseHome(json);
        }),
        toArray()
      );
  }

  public fetchBookmarks(userId: string): Observable<unknown> {
    return this.fetchURL(this.seeksURL + "/seeks/" + userId, "GET", undefined, {
      "x-auth-token": this.generateTransactionXAuthToken(userId),
      "Content-Type": "application/json",
    });
  }

  public fetchProgramWatchNext(user: ILoggedInUser, programId: string): Observable<any> {
    return this.fetchURL(
      // !!!! why "platform=apps_tv" and not platform=smart_tv
      this.apiMobileURL + "/apps/transactions/" + user.id + "/history/" + programId + "?platform=apps_tv",
      "GET",
      undefined,
      {
        "x-auth-token": this.generateTransactionXAuthToken(user.id),
        "Content-Type": "application/json",
      }
    ).pipe(
      mergeMap(json => {
        return of(json);
      }),
      toArray()
    );
  }

  public fetchProgramMeta(programPath: string): Observable<any> {
    return this.fetchApiMobile("/apps/program/meta/" + programPath).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        this.fetchError(e);
        return EMPTY;
      }),
      mergeMap(json => {
        return parseJSON(json);
      }),
      toArray()
    );
  }

  public putHits(user: ILoggedInUser, video: PlayableItem, progress: number): Observable<any> {
    const duration = video.metadata.duration;
    return this.fetchSeeks(
      "/seeks/" + user.id,
      "PUT",
      {
        videoId: video.id,
        progress: Math.max(0, Math.min(duration, Math.floor(progress))).toString(), // ensure progress is between 0 and duration
        duration: duration.toString(),
        playUntil: video.media?.end_date ? video.media.end_date.toString() : "",
        programId: video.extras.program ? video.extras.program.id : video.id,
        broadcastBeginDate:
          video.media?.broadcast?.start_date.toString() ||
          (video.media?.start_date ? video.media.start_date.toString() : ""),
        saison: video.extras.season || "",
        episode: video.extras.episode || "",
      },
      {
        "x-auth-token": this.generateTransactionXAuthToken(user.id),
        "Content-Type": "application/json",
      }
    ).pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        Log.api.error("Plugin putHits error", e);
        return EMPTY;
      })
    );
  }

  /**
   * Fetch and send didomi (CMP) consents datas
   */

  public getDidomiConsents(id: string, access_token: string): Observable<unknown> {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };

    return this.fetchURL(
      `${this.didomi.apiUrl}/v1/consents/users`,
      "GET",
      {
        organization_id: this.didomi.organizationId,
        organization_user_id: id,
      },
      headers
    ).pipe(
      mergeMap(json => {
        return of(json);
      })
    );
  }

  public createDidomiAccessToken(): Observable<unknown> {
    const headers = { "Content-Type": "application/json" };
    const body = { type: "api-key", key: this.didomi.privateAPIKey, secret: this.didomi.secretAPIKey };

    return this.fetchURL(`${this.didomi.apiUrl}/v1/sessions/`, "POST", body, headers).pipe(
      mergeMap(json => {
        return of(json);
      })
    );
  }

  public updateDidomiConsents(id: string, consents: AppConsents, access_token: string): Observable<any> {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };

    return this.fetchURL(
      `${this.didomi.apiUrl}/v1/consents/events?organization_id=${this.didomi.organizationId}&organization_user_id=${id}`,
      "POST",
      {
        user: {
          organization_user_id: id,
          metadata: { custom_key: `${"notice_tvc_francetvc"}` },
        },
        consents,
      },
      headers
    ).pipe(
      mergeMap(json => {
        return of(json);
      })
    );
  }

  public getEdgescapeJson(): Observable<any> {
    return this.fetchURL("https://geo-info.ftven.fr/ws/edgescape.json", "GET").pipe(
      mergeMap(json => {
        return of(json);
      })
    );
  }
}
